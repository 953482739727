import * as React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../../components/layout'
import { ProductCategoryCards } from '../../components/commerce/category/ProductCategoryCards'

const ProductsPage = () => {
  return (
    <Layout>
      <Row className="mt-5">
        <Col md={6}>
          <p>
            We supply a wide range of quality rural farm products such as fencing and stock handling equipment. 
            We also supply a range of steel for building and fabrication.
          </p>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <div>
            <button className="primary">
              <FontAwesomeIcon icon={faNewspaper} />Latest Catalogue
            </button>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={12}>
          <ProductCategoryCards />
        </Col>
      </Row>
    </Layout>
  )
}

export default ProductsPage

export const Head = () => {
  return (
    <>
      <title>Products Page | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}
