import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './product_category_cards.scss'

const categories = [
  {
    title: 'Tubular Steel',
    image: '../../../images/tube-and-pipe.jpeg',
    link: '/products/tubular-steel'
  },
  {
    title: 'Structural & Merchant Bar',
    image: '../../../images/merchant-bar.jpeg',
    link: '/products/structural-merchant-bar'
  },
  {
    title: 'Livestock Handling',
    image: '../../../images/livestock.jpeg',
    link: '/products/livestock-handling'
  },
  {
    title: 'Fencing',
    image: '../../../images/fencing.jpeg',
    link: '/products/fencing'
  },
  {
    title: 'Other',
    image: '../../../images/accessories.jpeg',
    link: '/products/other'
  },
  {
    title: 'All Products',
    image: '../../../images/cattle-handling.jpeg',
    link: '/products/all'
  }
]

export const ProductCategoryCards = () => {

  return (
    <div className="shop-category-container">
      <div className="title-container">
        <h2 className="title">Shop By Category</h2>
      </div>

      <Row>
        <Col xs={4} className="mt-4">
          <Link to="/products/tubular-steel" className="category-link">
            <div className="category-card">
              <div className="image">
                <StaticImage
                  src="../../../images/tube-and-pipe.jpeg"
                  alt="Tube & Pipe"
                  width={340}
                  height={278}
                  placeholder="blurred"
                  layout="fixed" />
              </div>
              <div className="content">
                <Row>
                  <Col xs={10} className="d-flex align-items-center">
                    <h3>Tubular Steel</h3>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={4} className="mt-4">
          <Link to="/products/structural-merchant-bar" className="category-link">
            <div className="category-card">
              <div className="image">
                <StaticImage
                  src="../../../images/merchant-bar.jpeg"
                  alt="Merchant Bar"
                  width={340}
                  height={278}
                  placeholder="blurred"
                  layout="fixed" />
              </div>
              <div className="content">
                <Row>
                  <Col xs={10} className="d-flex align-items-center">
                    <h3>Structural & Merchant Bar</h3>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={4} className="mt-4">
          <Link to="/products/livestock-handling" className="category-link">
            <div className="category-card">
              <div className="image">
                <StaticImage
                  src="../../../images/livestock.jpeg"
                  alt="Livestock"
                  width={340}
                  height={278}
                  placeholder="blurred"
                  layout="fixed" />
              </div>
              <div className="content">
                <Row>
                  <Col xs={10} className="d-flex align-items-center">
                    <h3>Livestock Handling</h3>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={4} className="mt-4">
          <Link to="/products/fencing" className="category-link">
            <div className="category-card">
              <div className="image">
                <StaticImage
                  src="../../../images/fencing.jpeg"
                  alt="Fencing"
                  width={340}
                  height={278}
                  placeholder="blurred"
                  layout="fixed" />
              </div>
              <div className="content">
                <Row>
                  <Col xs={10} className="d-flex align-items-center">
                    <h3>Fencing</h3>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={4} className="mt-4">
          <Link to="/products/other" className="category-link">
            <div className="category-card">
              <div className="image">
                <StaticImage
                  src="../../../images/accessories.jpeg"
                  alt="Accessories"
                  width={340}
                  height={278}
                  placeholder="blurred"
                  layout="fixed" />
              </div>
              <div className="content">
                <Row>
                  <Col xs={10} className="d-flex align-items-center">
                    <h3>Other</h3>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
        <Col xs={4} className="mt-4">
          <Link to="/products/all" className="category-link">
            <div className="category-card">
              <div className="image">
                <StaticImage
                  src="../../../images/cattle-handling.jpeg"
                  alt="Cattle Handling"
                  width={340}
                  height={278}
                  placeholder="blurred"
                  layout="fixed" />
              </div>
              <div className="content">
                <Row>
                  <Col xs={10} className="d-flex align-items-center">
                    <h3>All Products</h3>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  )
}
